import React from 'react'

import { Section } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

export const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const windowWidth = useWidth(300)

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_1',
      section_name: 'Conta Condomínio gratuita',
      element_action: 'click button',
      element_name: 'Abrir conta agora',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
      '_blank',
    )
  }

  const textColor = windowWidth < WIDTH_MD ? 'text-white' : 'text-grayscale--500'

  return (
    <Section role='img' aria-label='Mulher sorridente usando seu smartphone.' className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h1 className={`${textColor} fs-24 fs-lg-40 fs-xl-56 lh-28 lh-lg-48 lh-xl-67 fw-500 text-grayscale--500`}>
              Conta Condomínio gratuita
            </h1>
            <p className={`${textColor} fs-14 lh-17 fs-lg-18 lh-lg-22 fs-xl-18 lh-xl-22 mt-3 mb-0 text-grayscale--500`}>
              Soluções financeiras sem taxas e totalmente online para destravar a gestão do seu condomínio
            </p>
            <button
              className={`${windowWidth < WIDTH_MD ? 'btn btn-white' : 'btn-orange--extra'} fs-14 fw-700 text-none mt-4 py-0 rounded-2`}
              onClick={handleLink}
            >
              Abrir conta agora
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}
