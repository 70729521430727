import React from 'react'

import Building from '@interco/icons/build-v4/orangeds/MD/building'
import Flash from '@interco/icons/build-v4/orangeds/MD/flash-off'
import Users from '@interco/icons/build-v4/orangeds/MD/users'
import Paint from '@interco/icons/build-v4/orangeds/MD/paint-roller'
import Add from '@interco/icons/build-v4/orangeds/MD/add'

import { brand } from 'src/styles/colors'

export const data = [
    {
        icon: <Building width={24} height={24} color={brand.claryOrange} />,
        title: 'Incêndio',
    },
    {
        icon: <Flash width={24} height={24} color={brand.claryOrange} />,
        title: 'Danos elétricos',
    },
    {
        icon: <Users width={24} height={24} color={brand.claryOrange} />,
        title: 'Responsabilidade Civil',
    },
    {
        icon: <Paint width={24} height={24} color={brand.claryOrange} />,
        title: 'Portões danificados',
    },
    {
        icon: <Add width={24} height={24} color={brand.claryOrange} />,
        title: 'e muito mais!',
    },
]
