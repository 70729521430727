import React from 'react'

import ImageWebp from 'src/components/ImageWebp'

import { Section } from './style'
import { data } from './_data'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const Seguro = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_4',
      section_name: 'Seguro para condomínios',
      element_action: 'click button',
      element_name: 'Solicitar conta condomínio',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
      '_blank',
    )
  }

  return (
    <Section>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 order-md-last d-flex justify-content-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/condominiopj-dobra4/image.webp'
              altDescription='Homem acessando sua conta condomínio através do seu smartphone.'
              arrayNumbers={[ 312, 336, 376, 456 ]}
            />
          </div>

          <div className='col-12 col-md-6 col-xl-5 offset-lg-1'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-48 lh-xl-60 text-grayscale--500 fw-500 mt-3 mb-md-3 pb-2 pb-md-0'>
              Seguro para condomínios
            </h2>

            <p className='fs-14 fs-lg-18 lh-16 lh-md-17 lh-lg-22 text-grayscale--400 mb-3 pb-2 pb-md-0'>
              <span className='d-md-block'>Garanta a segurança do seu condomínio com</span> condições exclusivas.
            </p>

            <p className='fs-14 fs-lg-18 lh-16 lh-md-17 lh-lg-22 text-grayscale--400 mb-3 pb-2 pb-md-0'>
              <span className='d-md-block'>Conte com assistência 24h e garanta</span> proteção contra:
            </p>

            {data?.map((item: typeof data[0], index: number) => (
              <div className='d-flex mb-3 align-items-center' key={index}>
                <div>{item.icon}</div>
                <h3 className='fw-700 fs-16 lh-20 text-grayscale--500 mb-0 ml-3'>
                  {item.title}
                </h3>
              </div>
            ))}

            <button className='btn btn-orange--extra text-none mt-4' onClick={handleLink}>
              Solicitar conta condomínio
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}
