import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import {
  Credito,
  ComoAbrirMinhaConta,
  FaqSection,
  Economize,
  Hero,
  Seguro,
  Conheca,
} from './sections/_index'

const ContaCondominio = () => {
  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <Conheca />
      <Economize />
      <Seguro />
      <Credito />
      <ComoAbrirMinhaConta />
      <FaqSection />
    </Layout>
  )
}

export default ContaCondominio
