import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { white, brand } from 'src/styles/colors'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', sans-serif;
  }

  h3 {
    font-family: 'Inter', sans-serif;
  }

  .content-text {
    @media ${device.tablet} {
      position: relative;
      top: -20px;
    }

    @media ${device.desktopXL} {
      position: relative;
      top: -25px;
    }
  }
  
  padding-top: 56px;
  padding-bottom: 56px;
  background: ${brand.claryOrange};

  @media ${device.tablet} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  button {
    height: 48px;
    color: #943D15;
    font-weight: 700;

    @media ${device.tablet} {
      width: fit-content;
    }
  }

  .react-multi-carousel-track {
    @media ${device.tablet} {
      padding: 0 0 30px!important;
    }
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        background-color: #FFFFFF;
        opacity: 1;
      }
    }
  }

  .react-multi-carousel-dot-list  {
    .react-multi-carousel-dot--active {
      button {
        background-color: #FF7A00;
        opacity: 1;
      }
    }
  }
`

export const Card = styled.div`
  background: ${white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px;
  border-radius: 9px;
  width: 166px;
  height: 203px;
  margin-top: 25px;

  img {
    width: 42px;
    height: 42px;
  }

  @media ${device.tablet} {
    margin-top: 0;
    margin-bottom: 25px;
  }
`
