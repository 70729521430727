import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', sans-serif;
  }

  h3 {
    font-family: 'Inter', sans-serif;
  }

  padding-top: 64px;
  padding-bottom: 64px;

  @media ${device.desktopXL} {
    padding-top: 156px;
    padding-bottom: 156px;
  }

  button {
    height: 48px;

    @media ${device.tablet} {
      width: 295px;
    }

    @media ${device.desktopLG} {
      width: 394px;
    }
  }
`
