import React from 'react'
import * as S from './styles'
import CardsInfo from './CardsInfo.json'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type CardsInfoProps = {
  index: string;
  text: string;
  description: string;
}

export const ComoAbrirMinhaConta = () => {
  const [ sendDataLayerEvent ] = useDataLayer()
  const windowWidth = useWidth(300)

  const handleLink = () => {
    sendDataLayerEvent({
      section: 'dobra_6',
      section_name: 'Como abrir minha conta condomínio gratuita?',
      element_action: 'click button',
      element_name: 'Abrir conta condomínio',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
      '_blank',
    )
  }

  return (
    <S.ContentHeader>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <S.DivPrincipal>
              {windowWidth >= WIDTH_MD && <S.OrangeLine />}
              <h2 className='fs-24 lh-28 text-orange--extra text-center fw-500'>Como abrir minha conta condomínio gratuita?</h2>
              <S.CardContainer className='row'>
                {CardsInfo.map((item: CardsInfoProps) => {
                  return (
                    <S.Cards
                      key={`card${item.index}`}
                      className={`card${item.index} col-12 col-md-3`}
                    >
                      <S.Number className={`number${item.index}`}>{item.index}</S.Number>
                      <div className='bg-grayscale--100'>
                        <h3 className={`cardTop${item.index} cardTop text-center fw-600 fs-16 lh-20 px-3 px-md-2 pt-5 pb-2 bg-white text-grayscale--500`}>{item.text}</h3>
                        <p className='cardText text-center fs-14 lh-17 bg-white text-grayscale--400 px-3 pb-4'>{item.description}</p>
                      </div>
                    </S.Cards>
                  )
                })}
              </S.CardContainer>
              <div className='d-flex justify-content-center mt-4'>
                <button className='btn btn-orange--extra text-none py-4' onClick={handleLink}>
                  Abrir conta condomínio
                </button>
              </div>
            </S.DivPrincipal>
          </div>
        </div>
      </div>
    </S.ContentHeader>
  )
}
