import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import IcContractPix from '@interco/icons/core/files/ic_contract_pix'

import ConhecaOsServicosJSON from '../../assets/data/ConhecaOsServicos.json'

import { Card, Section } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const Conheca = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_2',
      section_name: 'Conheça os serviços disponíveis na sua conta condomínio',
      element_action: 'click button',
      element_name: 'Abrir conta condomínio gratuita',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
      '_blank',
    )
  }

  type ConhecaOsServicosProps = {
    icon: string;
    title: string;
  }

  return (
    <Section className='py-5 pb-md-0'>
      <div className='container'>
        <div className='row align-items-md-start'>
          <div className='col-md-6'>
            <Card className='d-flex align-items-center flex-column px-4 h-100'>
              <h3 className='fs-24 fs-xl-40 lh-28 lh-xl-48 fw-500 text-grayscale--500 mb-3 mt-4 pt-2'>
                <span className='d-block text-center'>Super App</span>
                Inter Empresas
              </h3>
              <p className='text-center fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--400 mb-4 mb-md-4'>
                Mais agilidade para você, mais {' '}
                <span className='d-lg-block'>segurança para o seu condomínio.</span>
              </p>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/condominiopj-dobra2-mockup/image.webp'
                altDescription='Tela da home do aplicativo Inter Empresas em um smartphone'
                arrayNumbers={[ 254, 268, 292, 292 ]}
              />
            </Card>
          </div>

          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 mt-4'>
            <h2 className='fs-24 fs-lg-32 fs-xl-48 lh-28 lh-lg-38 lh-xl-54 text-grayscale--500 fw-500'>
              Conheça os serviços disponíveis na sua conta condomínio
            </h2>
            <p className='fs-14 fs-xl-18 lh-17 lh-xl-22 text-grayscale--400 mt-3 mb-4'>
              Tudo o que seu condomínio precisa em um só lugar
            </p>
            {
              ConhecaOsServicosJSON.map((item: ConhecaOsServicosProps) => (
                <div key={item.title} className='d-flex align-items-center mb-2'>
                  <div>
                    {item.icon !== ''
                      ? <IconsSwitch
                          icon={item.icon}
                          size='MD'
                          color='#943D15'
                          lib='frontend-lib'
                          customPath='brand/'
                        />
                      : <IcContractPix height={24} width={24} color='#943D15' />}
                  </div>
                  <div className='ml-2'><p className='fs-14 lh-16 mb-0 text-grayscale--500 fw-600'>{item.title}</p></div>
                </div>
              ))
            }
            <button
              className='btn btn-orange--extra text-none mt-4 mt-md-2 mt-lg-1 mt-xl-4'
              onClick={handleLink}
            >
              Abrir conta condomínio gratuita
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}
