import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  height: 406px;
  background: ${orange[500]};
  
  h1 {
    font-family: 'Citrina', sans-serif;
  }

  @media ${device.tablet} {
    height: 422px;
    background: transparent;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/condominiospj-hero/image.webp');
    background-size: cover;
  }

  @media ${device.desktopLG} {
    height: 562px;
  }

  @media ${device.desktopXL} {
    height: 790px;
  }

  button {
    color: ${orange.extra};
    height: 48px;

    @media ${device.tablet} {
      width: 289px;
      color: ${white};
    }

    @media ${device.desktopLG} {
      width: 378px;
    }

    @media ${device.desktopXL} {
      width: 457px;
    }
  }
`
