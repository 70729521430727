import React from 'react'

import Faq from 'src/components/Faq/index'
import { white } from 'src/styles/colors'

import { Section } from './style'
import pageContext from './../../pageContext.json'

export const FaqSection = () => {
  return (
    <Section className='bg-grayscale--100'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-xl-center mb-3'>
              Dúvidas frequentes
            </h2>
          </div>
          <Faq
            id='recarga'
            className=''
            answerData={pageContext.structuredData.faq}
            searchBg={white}
            answersColor='text-grayscale--500'
            columns={{ lg: 1, xl: 1 }}
          />
        </div>
      </div>
    </Section>
  )
}
