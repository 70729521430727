import React, { useState } from 'react'

import ImageWebp from 'src/components/ImageWebp'

import { Card, Divider, Section } from './style'
import { FormData } from './_FormData'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const Economize = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ total, setTotal ] = useState(0)

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_3',
      section_name: 'Economize com uma conta condomínio 100% gratuita',
      element_action: 'click button',
      element_name: 'Abrir conta condomínio gratuita',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
      '_blank',
    )
  }

  function floatToCurrency (floatNumber: number) {
    return floatNumber.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  return (
    <Section id='simulador'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-lg-5 col-xl-5 mb-4 mb-md-5 mb-lg-0'>
            <div className='row align-items-md-center'>
              <div className='col-md-6 col-lg-12'>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/condominiopj-dobra3/image.webp'
                  altDescription='Imagem de um prédio. Ao fundo vemos um ceú azul.'
                  arrayNumbers={[ 340, 336, 376, 456 ]}
                  arrayNumbersHeight={[ 312, 336, 376, 456 ]}
                />
              </div>
              <div className='col-md-6 col-lg-10 mt-lg-4'>
                <h2 className='font-sora fw-500 fs-24 lh-30 text-white mt-4 mt-md-0 mb-3 mr-n2'>
                  Economize com uma conta condomínio 100% gratuita
                </h2>
                <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-0 mr-n2 mr-lg-n3'>
                  <span className='d-lg-block'>Com o Super App Inter Empresas você</span> economiza tempo e dinheiro
                </p>
                <button className='btn btn-white text-orange--extra mt-4' onClick={handleLink}>
                  Abrir conta condomínio gratuita
                </button>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl-5 offset-xl-2 mt-2 mt-md-n1 mt-lg-0 mb-4 mb-md-0'>
            <Card>
              <p className='font-citrina text-grayscale--500 fw-500 fs-18 fs-xl-24 lh-20 fs-md-20 lh-md-25 mb-3 mr-n2 text-md-center text-lg-left'>
                Faça a simulação e veja quanto você pode passar a poupar anualmente
              </p>

              <div className='mb-4'>
                <FormData setTotal={setTotal} />
              </div>

              <p className='font-inter fw-600 fs-20 lh-25 text-grayscale--400 mb-2'>
                Total
              </p>
              <p className='fw-600 fs-24 lh-30 font-inter text-orange--extra mb-1'>
                R$ {floatToCurrency(total)}
              </p>
              <Divider />
              <p className='fs-14 lh-17 font-inter text-grayscale--400 mb-0 mt-3 mr-lg-n2'>
                Caso você tenha mais de 100 condôminos esse valor pode mudar
              </p>
            </Card>
          </div>
        </div>
      </div>
    </Section>
  )
}
